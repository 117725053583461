// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gerichte-js": () => import("./../../../src/pages/gerichte.js" /* webpackChunkName: "component---src-pages-gerichte-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qr-code-js": () => import("./../../../src/pages/qr-code.js" /* webpackChunkName: "component---src-pages-qr-code-js" */),
  "component---src-pages-uber-uns-js": () => import("./../../../src/pages/uber-uns.js" /* webpackChunkName: "component---src-pages-uber-uns-js" */)
}

